import React from 'react'
import { Link } from "react-router-dom";
import './Css/style.css';
import Image from 'react-image-webp';

function index({isBookSlot}) {
  return (

    <>
       <section className="baner-region">
	
  <div className="container">
  <div className="row">
  <div className="col-md-8 col-xs-12">
  <div className='left-side ml-70'>
    <h3>Experienced doctor at home</h3>
    <h1>In 60 Minutes</h1>
    
    <p className='d-md-none d-lg-none'>Get a home visit from our experienced <br/>
& dedicated General Physicians who are <br/>
available 24X7.</p>

<p className='mob-noee'>Get a home visit by our experienced & dedicated General Physicians <br/> who are available 24X7. Kyno brings you the reliability of a 'family doctor'<br/>
 along with a wide range of at-home medical services.</p>
 {isBookSlot ? <><p>Day Consultation Charges - <b>Rs. 1000</b></p>
<p>Night consultation Charges - <b>Rs. 1500</b></p>
<p>Pay <b>Rs. 99</b> Now to Book Your Appointment</p>
 <a className="btn btn-warning " href="/booking">Book Appointment Now</a></> : <Link className="btn btn-warning " to="tel:+919953104104">Call me: 99531 04104</Link>}

  </div>
  </div>
  
  
  <div className="col-md-4 col-xs-12">
  <div className='right-side'>
  <div className='aft'>
    <img src='images/Topology-1.svg' alt='Topology' />
    </div>
    <div className='tag'>
    <Image
      src={require('../images/tags-1.png')}
      webp={require('../images/tags-1.webp')}
      alt='tagsImg'
    />
    {/* <img src='images/tags-1.png' alt='tags' /> */}
    </div>

    <Image
      src={require('../images/bnr-img.png')}
      webp={require('../images/bnr-img.webp')}
      className='bnrimg img-fluid'
      alt='bnrImg'
    />
    {/* <img src={bnrImg} alt='bnr-img' className='bnrimg img-fluid'/> */}
    
  </div>
  </div>
  </div>   		
  </div>
  </section>
    </>

  )
}

export default index
